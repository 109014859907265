// import React from "react";
import React, { Component } from "react";
import { HomeIcon } from "@heroicons/react/solid";
import { DownloadIcon, ChevronRightIcon } from "@heroicons/react/outline";
import { Link } from "react-router-dom";

// export default function Angebotangenommen() {
//   return (
//     <div className="px-4 mb-6">
//       <h3 className="font-bold">Test it</h3>
//       <p className="mb-4">Das ist ein test.........heyhey</p>

//       <a
//         href="https://salzburg-ag.at"
//         className="flex items-center font-bold text-primary"
//       >
//         <HomeIcon className="w-6 h-6 mr-2" />
//         Zu salzburg-ag.at
//       </a>

//       <Link
//         to="/step5"
//         className="flex justify-between px-4 py-3 mt-4 text-white rounded-lg bg-primary"
//       >
//         Absenden
//         <ChevronRightIcon className="w-6 h-6" />
//       </Link>
//     </div>
//   );
// }

export default class FormDataComponent extends Component {
  userData;
  constructor(props) {
    super(props);
    this.onChangeName = this.onChangeName.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangePhone = this.onChangePhone.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.state = {
      name: "",
      email: "",
      phone: "",
    };
  }
  // Form Events
  onChangeName(e) {
    this.setState({ name: e.target.value });
  }
  onChangeEmail(e) {
    this.setState({ email: e.target.value });
  }
  onChangePhone(e) {
    this.setState({ phone: e.target.value });
  }
  onSubmit(e) {
    e.preventDefault();
    this.setState({
      name: "",
      email: "",
      phone: "",
    });
  }
  // React Life Cycle
  componentDidMount() {
    this.userData = JSON.parse(localStorage.getItem("user"));
    if (localStorage.getItem("user")) {
      this.setState({
        name: this.userData.name,
        email: this.userData.email,
        phone: this.userData.phone,
      });
    } else {
      this.setState({
        name: "",
        email: "",
        phone: "",
      });
    }
  }
  componentWillUpdate(nextProps, nextState) {
    localStorage.setItem("user", JSON.stringify(nextState));
  }

  render() {
    return (
      <div className="container">
        <form onSubmit={this.onSubmit}>
          <div className="form-group">
            <label>Name</label>
            <input
              type="text"
              className="form-control"
              value={this.state.name}
              onChange={this.onChangeName}
            />
          </div>
          <div className="form-group">
            <label>Email</label>
            <input
              type="email"
              className="form-control"
              value={this.state.email}
              onChange={this.onChangeEmail}
            />
          </div>
          <div className="form-group">
            <label>Phone</label>
            <input
              type="tel"
              className="form-control"
              value={this.state.phone}
              onChange={this.onChangePhone}
            />
          </div>
          <button type="submit" className="btn btn-primary btn-block">
            Submit
          </button>
        </form>
      </div>
    );
  }
}
